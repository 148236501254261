import React from 'react'
import Layout from '@components/Layout'
import { Link, graphql } from 'gatsby'
import { Seo } from '@components/Seo'
import PageTitle from '@components/PageTitle'
import { Article, TitleParagraph } from '@global'

const News = ({ data }) => (
  <Layout>
    <Seo title="News and Articles | The Town and Business Guide For Residents and Visitors" />
    <PageTitle title="News and Articles" />
    <TitleParagraph>Here you will find news and articles relating to Sowerby Bridge</TitleParagraph>
    <Article>
      {data.allDatoCmsNews.edges.map(({ node }) => (
        <div key={node.id} className="post">
          <h1 className="post-title">
            <Link to={node.slug}>
              <h2>{node.title}</h2>
            </Link>
          </h1>
          <b>{node.date}</b>
          <p>{node.contentNode.childMarkdownRemark.excerpt}</p>
        </div>
      ))}
    </Article>
  </Layout>
)

export const newsquerygraph = graphql`
  query newsquery {
    allDatoCmsNews(sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          id
          date(formatString: "DD MMM YYYY")
          slug
          contentNode {
            childMarkdownRemark {
              excerpt(pruneLength: 250)
            }
          }
        }
      }
    }
  }
`

export default News
